import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import { getCourseNavigation } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/stimmungsbarometer/aufgabe-1" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Die Eindrücke, die in das Gedicht ›Andenken‹ eingegangen sind, hat
          Hölderlin während seiner Zeit in Bordeaux gesammelt. Dort erwartete
          den damals 31-jährigen Dichter Anfang des Jahres 1802 eine neue Stelle
          im Haus des hamburgischen Konsuls und Weinhändlers Daniel Christoph
          Meyer. Die nunmehr fünfte Hauslehrerstelle innerhalb von 10 Jahren.
          Auch diesmal trat Hölderlin den Weg im Dezember 1801 von seiner
          Heimatstadt Nürtingen aus zu Fuß an. Am 28. Januar 1802 erreichte er
          die Hafenstadt Bordeaux.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
